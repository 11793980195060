<template >
  <div class="w-100 results">
    <div class="global-green flex ac-c" >
      <div class="flex w-100 jc-c">
        <div class="image w-100">
          <img class="blurred" :src="!data.benchmarkImage ? logo : imageServerUrl + data.benchmarkImage" alt="Benchmark image" height="200"/>
          <div class="meta w-100">
            <div class="flex col w-100 ac-c center-xs">
              <img class="circle" :src="!data.benchmarkImage ? logo : imageServerUrl + data.benchmarkImage" alt="Benchmark image" height="200"/>
              <h2>{{data.benchmark.name}}</h2>
              <div class="valid">VALID RESULT</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex w-100 mt-30 jc-c">
      <div class="flex w-90 pt-15 max-width">
        <div class="col-xs-12 col-md-4 mb-30" v-if="data.isLoaded">
          <div class="score-label mb-10">{{data.benchmark.name}} Score</div>
          <div class="score mb-20">{{formattedScore}}</div>
          <div class="label mb-10">Time</div>
          <div class="value mb-20">{{formattedTime}}</div>
          <div class="label mb-10">Benchmark set</div>
          <div class="value mb-20">{{data.benchmark.name}}</div>
          <div class="label mb-10">Description</div>
          <div class="value mb-20">{{data.benchmark.info}}</div>
        </div>
        <div class="col-xs-12 col-md-8 mb-30">
          <apexchart type="line" height="300" ref="chart" :options="graphData.chartOptions" :series="graphData.series"></apexchart>
        </div>
      </div>
    </div>

    <div class="global-green flex w-100 jc-c">
      <div class="flex w-90 max-width">
        <h2 class="col-xs-12 center-xs mt-30">Test Results</h2>
        <div class="result-test col-xs-12 col-sm-6 col-lg-5 mt-30" v-for="(test, index) in data.tests" :key="index">
            <test-results-tile :test="test"></test-results-tile>
        </div>
      </div>
    </div>

    <div class="flex w-100 jc-c pb-30">
      <div class="flex w-90 max-width">
        <h2 class="col-xs-12 center-xs mt-30">Features</h2>
        <div class="flex w-100 mt-30" v-for="(item, index) in featureTests" :key="index">
          <div class="feature-header flex jc-c ac-c w-100">
            <div class="feature-set-available"><b>{{item.supported}}</b> {{item.name}} features out of <b>{{item.total}}</b> available</div>
          </div>
          <div class="dropdown flex" :id="item.name">
            <div class="col-xs-12 col-md-4 feature-holder p-10" :class="{'green': feature.isSupported, 'red': !feature.isSupported}" v-for="(feature, index) in data.features[item.name]" :key="index">
              <div :class="{'check': feature.isSupported, 'cross': !feature.isSupported}"></div>
              <div class="feature-label" :class="{'available': feature.isSupported, 'hastooltip': feature.value}">
                {{feature.feature}}
                <span v-if="feature.value" class="tip">{{feature.value}}</span>
              </div>
            </div>
          </div>
          <div class="details-button" @click="openDropdown(item.name)" :id="item.name + '-button'">Show details</div>
        </div>
      </div>
    </div>

    <div class="global-green flex w-100 jc-c mt-30 pb-30">
      <div class="device flex w-90 max-width">
        <h2 class="col-xs-12 center-xs mt-30 mb-30">Device Information</h2>
        <div class="col-xs-12 mb-30">
          <div class="device-header">Display</div>
          <div class="flex display" v-for="(value, label) in data.screen" :key="label">
            <div class="col-xs-5 display-label">{{label}}</div>
            <div class="col-xs-7 display-value">{{value}}</div>
          </div>
        </div>

        <div class="col-xs-12">
          <div class="device-header">Browser</div>
          <div class="flex display" v-for="(value, label) in data.browser" :key="label">
            <div class="col-xs-5 display-label">{{label}}</div>
            <div class="col-xs-7 display-value">{{value || "-"}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, reactive, ref, onBeforeMount, inject } from 'vue'
import { get } from "@/lib/api";
import { useRoute } from "vue-router";
import { TestResultsTile } from '@/components';
const logo = require('@/assets/default.png')

const route = useRoute();
const imageServerUrl = inject('imageServerUrl')

const chart = ref(null)
const data = reactive({
    resultData: '',
    benchmark: '',
    screen: null,
    browser: null,
    features: {},
    tests: [],
    benchmarkImage: '',
    fpsList: [],
    categoryList: [],
    isLoaded: false
})

const featureTests = computed(()=>{
    const featureResults = []
    for(let key of Object.keys(data.features)){
        const value = data.features[key]
        const temp = {}
        temp.name = key;
        temp.total = value.length
        if(value.length){
            temp.supported = value.reduce((totalSupportedCount, feature)=>{
                if(feature.isSupported) totalSupportedCount++
                return totalSupportedCount
            }, 0)

        }
        featureResults.push(temp)
    }
    return featureResults

})

function addGraphData(){
    data.tests.forEach((test) => {
            if(test.results.data[0].values.length) {
                chart.value.addXaxisAnnotation(
                    {
                        x: data.fpsList.length.toString(),
                        label: {
                            text: test.name,
                        }
                    }
                )
                data.fpsList.push(...test.results.data[0].values)
            }
        })
    data.categoryList = [...Array(data.fpsList.length).keys()]
    data.categoryList = data.categoryList.map((item)=>{
        return item.toString()
    })
    chart.value.updateOptions({
        xaxis: {
            categories: data.categoryList,
            tickAmount: 10,
        }
    })
}

const formattedTime = computed(() => {
    const d = new Date(data.resultData.testedAt);
    const time =  d.toLocaleString('en-US',{hour:'2-digit', minute:'2-digit', hour12:true});
    let stime = time.split(':');
    return `${("0" + d.getDate()).slice(-2)}/${("0" + (d.getMonth() + 1)).slice(-2)}/${d.getFullYear()} ${stime[0]+':'+stime[1]}`;
})

onBeforeMount(async () => {
    try {
        let res = await get(`users/me/results/${route.params.id}`, true)
        data.resultData = res.data
        data.benchmark = res.data.benchmark
        data.screen = res.data.device.screen
        data.browser = res.data.device.browser
        data.features = res.data.device.features
        data.tests = res.data.benchmark.tests
        data.benchmarkImage = res.data.benchmark.image
        data.isLoaded = true;
        addGraphData()
    }
    catch(error) {
        console.error("dataFetchError : " + error)
    }
})

const graphData = reactive({
    series: [{
        name: 'FPS',
        data: data.fpsList
    }],
    chartOptions: {
        chart: {
            id: 'chart1',
            type: 'line',
            height: 300,
            toolbar: {
                tools: {
                    download: false
                }
            }
        },
        title: {
            text: 'FPS Chart',
            align: 'left'
        },
        yaxis: {
            title: {
                text: 'FPS'
            },
        },
        xaxis: {
            tickAmount: 10,
        },
        tooltip: {
            shared: false,
            x: {
                show: false,
                formatter: (val)=> {
                    return 'Time : ' + val
                }
            }
        },
        stroke: {
            width: 2
        }
    }
})

function openDropdown(name) {
  const dropdown = document.getElementById(name);
  const button = document.getElementById(name + "-button");

  if (dropdown.classList.contains("open")) {
    dropdown.classList.remove("open");
    button.innerText = "Show details";
  } else {
    dropdown.classList.add("open");
    button.innerText = "Hide details";
  }
}

const formattedScore = computed(() => {
  return data.resultData.overallScore.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
})


</script>

<style lang="scss" scoped>
.feature-item {
    background-color: #cccccc;
    padding: 10px;
}

.feature-item:hover{
    cursor: pointer;
}
</style>